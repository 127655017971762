




















































import {
    computed,
    defineComponent,
    reactive,
    ref,
    toRefs,
} from "@vue/composition-api"
import { Coordinate } from "@/services/SpaceObjectsService"

export default defineComponent({
    name: "CoordinateInput",
    props: {
        errorMessages: [String, Array],
        label: { type: String, default: "" },
        value: {
            type: Object as () => Coordinate,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const form = ref(null as null | any)

        const state = reactive({
            menu: false,
            longitude: props.value?.x ?? null,
            latitude: props.value?.y ?? null,
        })

        function longitudeRule(s: string | null) {
            if (state.latitude === null && (s === "" || s === null)) return true
            const v = parseFloat(s ?? "")
            if (isNaN(v)) return "必須是數值"
            if (v >= 180 || v <= -180) return "必須為正負180內數值"
            return true
        }

        function latitudeRule(s: string | null) {
            if (state.longitude === null && (s === "" || s === null))
                return true
            const v = parseFloat(s ?? "")

            if (isNaN(v)) return "必須是數值"
            if (v >= 180 || v <= -180) return "必須為正負180內數值"
            return true
        }

        const text = computed(() => {
            if (!props.value) return "--"
            return `${props.value.x}, ${props.value.y}`
        })

        function clear() {
            state.longitude = null
            state.latitude = null
            emit("input", null)
        }

        function showPicker() {
            state.menu = true
        }

        function confirm() {
            if (!form.value!.validate()) return
            if (state.latitude === null && state.longitude === null) {
                emit("input", null)
                return
            }
            emit("input", <Coordinate>{
                x: state.longitude,
                y: state.latitude,
            })
            state.menu = false
        }

        return {
            form,
            ...toRefs(state),
            blur,
            clear,
            showPicker,
            text,
            confirm,
            longitudeRule,
            latitudeRule,
        }
    },
})
