







































































import {
    computed,
    defineComponent,
    reactive,
    ref,
    toRefs,
} from "@vue/composition-api"
import { Mileage, MileageType } from "@/services/SpaceObjectsService"
import { mileageNumberFormatter } from "@/utilities/Formatter"

export default defineComponent({
    name: "MileageInput",
    props: {
        errorMessages: [String, Array],
        label: { type: String, default: "" },
        value: {
            type: Object as () => Mileage,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const form = ref(null as null | any)

        const state = reactive({
            menu: false,
            type: props.value?.type ?? -1,
            pointValue: props.value?.value ?? null,
            start: props.value?.start ?? null,
            end: props.value?.end ?? null,
        })

        const typeProp = computed({
            get: () => state.type,
            set: (v) => {
                if (v === MileageType.Point) {
                    state.pointValue = state.start
                } else if (v === MileageType.Range) {
                    state.start = state.pointValue
                    state.end = state.pointValue
                }
                state.type = v
            },
        })

        function pointValueRule(s: string) {
            if (state.type != MileageType.Point) return true
            return !isNaN(parseFloat(s)) || "必須是數值"
        }

        function startRule(s: string) {
            if (state.type != MileageType.Range) return true
            const v = parseFloat(s)
            if (isNaN(v)) return "必須是數值"
            if (state.end != null && v > state.end) return "必須小於結束里程"
            return true
        }

        function endRule(s: string) {
            if (state.type != MileageType.Range) return true
            const v = parseFloat(s)
            if (isNaN(v)) return "必須是數值"
            if (state.start != null && v < state.start)
                return "必須大於起始里程"
            return true
        }

        const text = computed(() => {
            if (!props.value) return "--"

            if (props.value.type == MileageType.Point)
                return mileageNumberFormatter(props.value.value)
            else
                return `${mileageNumberFormatter(
                    props.value.start
                )} ~ ${mileageNumberFormatter(props.value.end)}`
        })

        function clear() {
            state.type = -1
            state.pointValue = null
            state.start = null
            state.end = null
            emit("input", null)
        }

        function showPicker() {
            state.menu = true
        }

        function confirm() {
            if (!form.value!.validate()) return
            if (state.type === -1) {
                emit("input", null)
                return
            }
            emit("input", <Mileage>{
                type: state.type,
                road: props.value?.road,
                value: state.pointValue,
                start: state.start,
                end: state.end,
            })
            state.menu = false
        }

        return {
            form,
            ...toRefs(state),
            blur,
            clear,
            showPicker,
            text,
            MileageType,
            pointValueRule,
            startRule,
            endRule,
            confirm,
            typeProp,
        }
    },
})
