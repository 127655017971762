









































































































import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import {
    SpaceObjectType,
    spaceObjectTypeFormatter,
    getSpaceObject,
    setSpaceObject,
    Coordinate,
    Mileage,
    postSpaceObject,
    CustomProperty,
} from "@/services/SpaceObjectsService"
import {
    closeDialog,
    DialogButtonType,
    errorDialog,
    infoDialog,
    successDialog,
} from "@/services/DialogService"
import MileageInput from "@/components/MileageInput.vue"
import PlacePagingSelect from "@/components/PlacePagingSelect.vue"
import CoordinateInput from "@/components/CoordinateInput.vue"
import PlaceCustomPropertyEditor from "./PlaceCustomPropertyEditor.vue"
import { router } from "@/services/Router"

export default defineComponent({
    components: {
        MileageInput,
        PlacePagingSelect,
        CoordinateInput,
        PlaceCustomPropertyEditor,
    },
    props: {
        type: {
            type: String as () => SpaceObjectType,
            required: true,
        },
        id: String,
    },
    setup(props) {
        const state = reactive({
            place: null as {
                id: string | null
                parentId: string | null
                number: string
                name: string
                type: SpaceObjectType
                coordinate: Coordinate | null
                bimId: string | null
                category: string | null
                mileage: Mileage | null
                customProperties: CustomProperty[]
            } | null,
        })
        async function loadData() {
            if (props.id) state.place = await getSpaceObject(props.id)
            else
                state.place = {
                    id: null,
                    parentId: null,
                    number: "請輸入編號",
                    name: "請輸入名稱",
                    type: props.type,
                    coordinate: null,
                    bimId: null,
                    category: null,
                    mileage: null,
                    customProperties: [],
                }
        }

        loadData()

        async function submit() {
            try {
                infoDialog("送出中", "請稍後", DialogButtonType.None)
                if (props.id) {
                    await setSpaceObject(state.place!)
                    closeDialog()
                    successDialog("異動成功")
                    loadData()
                } else {
                    const result = await postSpaceObject(state.place!)
                    closeDialog()
                    successDialog("異動成功")
                    router.replace(`/spaces/${result.id}`)
                }
            } catch (error) {
                console.error(error)
                closeDialog()
                errorDialog("異動失敗，請聯絡系統維護人員")
            }
        }

        return {
            ...toRefs(state),
            spaceObjectTypeFormatter,
            submit,
        }
    },
})
