

































import { CustomProperty } from "@/services/SpaceObjectsService"
import { defineComponent, reactive, toRefs } from "@vue/composition-api"
export default defineComponent({
    name: "PlaceCustomPropertyEditor",
    props: {
        value: {
            type: Array as () => CustomProperty[],
            required: true,
        },
    },
    setup(props) {
        const state = reactive({
            newPropertyName: "",
            newPropertyValue: "",
        })

        function add() {
            props.value.push({
                name: state.newPropertyName,
                value: state.newPropertyValue,
            })
            state.newPropertyName = ""
            state.newPropertyValue = ""
        }

        function remove(index: number) {
            props.value.splice(index, 1)
        }

        return {
            ...toRefs(state),
            add,
            remove,
        }
    },
})
